export const baseUrl = "https://lio.live";
export const fileBaseUrl = "https://lio.live/lio";

export const contact = "api/storecontactapi";
export const home = "api/homepageapi";





